<template>
  <div>
    <div
      class="sign-up-header"
      style="background-image: url('images/background.jpeg')"
    >
      <div class="content">
        <h1 class="mb-5">Manual Online Purchase Steps</h1>
        <p class="text-lg">
          COVID-19 PT Care Insurance Guideline Fo Online Manual Purchase Steps.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
